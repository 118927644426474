import * as React from 'react';
import Meta from 'components/Meta';
import BlackHeader from 'components/swipr/BlackHeader';
import DescFooter from 'components/DescFooter';

import 'styles/index.scss';

const TermsPage = () => {
  return (
    <>
      <Meta text={'Terms of Service'} />
      <main className={'desc'}>
        <BlackHeader text={'Terms of Service'} />
        <div className={'desc-content'}>
          <p>
            <strong>Welcome to BeFriend!</strong>
          </p>

          <p>
            Thank you for signing up to use our products, including the BeFriend
            application(the “BeFriend Platform”), website (the “Site”), and
            services, features, content, applications and widgets offered on the
            BeFriend Platform and Site (together, we’ll call these the
            “Services”). The Services are provided by BeFriend Inc. The term
            “you” or “User” refers to the person or entity accessing or
            otherwise using our Services.
          </p>

          <p>
            These Terms of Service (or “Terms” for short) describe the rules of
            BeFriend’s relationship with you and apply to your use of the
            Services. They are a legal agreement, so please read them carefully.
          </p>
          <p>
            By registering for and/or using the Services in any manner,
            including but not limited to by providing your Snapchat Bitmoji and
            display name, phone number, name, and user name, you agree to all of
            the terms and conditions contained in these Terms, which also
            incorporate BeFriend’s Privacy Policy and all other operating rules,
            policies and procedures that may be published from time to time on
            the BeFriend Platform by BeFriend, each of which may be updated by
            BeFriend from time to time without notice to you in accordance with
            the terms set out under the “Updates to These Terms” section below.
            In addition, some services offered through the Services may be
            subject to additional terms and conditions specified by BeFriend
            from time to time; your use of such services is subject to those
            additional terms and conditions, which are incorporated into these
            Terms by reference. These Terms apply to all users of the Services,
            including, without limitation, users who are contributors of
            content, information, and other materials or services on the Site,
            individual users of the Services, venues that access the Services,
            and users that have a page on the Services. IF YOU DO NOT AGREE WITH
            THESE TERMS, PLEASE DO NOT USE THESE SERVICES.
          </p>
          <p>
            The BeFriend Platform is a mobile application that enables people to
            meet new friends. It is not a dating app and is not intended to be
            used as a matchmaking service.
          </p>
          <p>
            <strong>I. Acceptance of the Present Terms & Conditions</strong>
            <br />
            By registering or using BeFriend on the mobile application or
            website (collectively, the “Service”), you acknowledge that the use
            of the Service is subject to BeFriend’s Terms of Service (“TOS”)
            which you must accept in order to use our Service. Furthermore, the
            use of our Service means that you also agree to our Privacy Policy
            and our Guidelines.
          </p>
          <p>
            <strong>II. Changes to the Terms</strong>
            <br />
            We amend these Terms from time to time. We may do this for a variety
            of reasons including to reflect changes in or requirements of the
            law, new features, or changes in business practices. The most recent
            version of this Agreement will be posted within the BeFriend app
            within Settings and also on swipr.cc, and you should regularly check
            for the most recent version. The most recent version is the version
            that applies. If the changes include material changes that affect
            your rights or obligations, we will notify you in advance of the
            changes by reasonable means, which could include notification
            through the BeFriend app. If you continue to use the Service after
            the changes become effective, then you agree to the revised
            Agreement. You agree that this Agreement shall supersede any prior
            agreements (except as specifically stated herein), and shall govern
            your entire relationship with BeFriend, including but not limited to
            events, agreements, and conduct preceding your acceptance of this
            Agreement.
          </p>
          <p>
            <strong> III. Who Can Use the Services</strong>
            <br />
            You must be at least 17 years of age to create an account on
            BeFriend and use the Service. By using the Service, you represent
            and warrant that:
          </p>

          <ul>
            <li>You can form a binding contract with BeFriend.</li>
            <li>
              You are not a person who is barred from receiving the Service
              under the laws of the United States or any other applicable
              jurisdiction.
            </li>
            <li>
              You have never been convicted of or pled no contest to a felony, a
              sex crime, or any crime involving violence, and you are not
              required to register as a sex offender with any state, federal or
              local sex offender registry.
            </li>
            <li>
              You will comply with this Agreement and all applicable local,
              state, national, and international laws, rules, and regulations.
            </li>
          </ul>

          <p>
            <strong>IV. Your Account with Us</strong>
            <br />
            To access or use BeFriend, you may need to create an account with
            us. When you create this account, you must provide accurate and
            up-to-date information. It is important that you maintain and
            promptly update your details and any other information you provide
            to us, to keep such information current and complete. For more
            information regarding the information we collect from you and how we
            use it, please consult our Privacy Policy.
          </p>

          <p>
            You are responsible for maintaining the confidentiality of your
            login credentials you use to sign up for BeFriend, and you agree
            that you are solely responsible (to us and to others) for the
            activity that occurs under your account. If you think someone has
            gained access to your account, please immediately contact us.
          </p>
          <p>
            <strong>V. Safety; Your Interactions with Other Users.</strong>
            <br />
            Though BeFriend strives to encourage a respectful and safe user
            experience through various safety features, it is not responsible
            for the conduct of any user on or off of the Service. You agree to
            use caution in all interactions with other users, particularly if
            you decide to communicate off the Service or meet in person. In
            addition, you agree to review and follow BeFriend’s Safety Tips
            prior to using the Service. You agree that you will not provide your
            financial information (for example, your credit card or bank account
            information), or wire or otherwise send money, to other users. YOU
            ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. YOU
            UNDERSTAND THAT BEFRIEND DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS
            ON ITS USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS USERS.
            BEFRIEND MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF
            USERS. BEFRIEND RESERVES THE RIGHT TO CONDUCT – AND YOU AGREE THAT
            BEFRIEND MAY CONDUCT – ANY CRIMINAL BACKGROUND CHECK OR OTHER
            SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES) AT ANY TIME
            USING AVAILABLE PUBLIC RECORDS.
          </p>
          <p>
            <strong>VI. Rights BeFriend Grants You</strong>
            <br />
            BeFriend grants you a personal, worldwide, royalty-free,
            non-assignable, nonexclusive, revocable, and non-sublicensable
            license to access and use the Service. This license is for the sole
            purpose of letting you use and enjoy the Service’s benefits as
            intended by BeFriend and permitted by this Agreement. Therefore, you
            agree not to:
          </p>
          <ul>
            <li>
              Use the Service or any content contained in the Service for any
              commercial purposes without our written consent; you have
              permission to mention our Service on user-generated content
              platforms such as Youtube and TikTok as long as you don’t claim
              that BeFriend endorses your content.
            </li>
            <li>
              Copy, modify, transmit, create any derivative works from, make use
              of, or reproduce in any way any copyrighted material, images,
              trademarks, trade names, service marks, or other intellectual
              property, content or proprietary information accessible through
              the Service without BeFriend’s prior written consent.
            </li>
            <li>
              Express or imply that any statements you make are endorsed by
              BeFriend.
            </li>
            <li>
              Use any robot, bot, spider, crawler, scraper, site
              search/retrieval application, proxy or other manual or automatic
              device, method or process to access, retrieve, index, “data mine,”
              or in any way reproduce or circumvent the navigational structure
              or presentation of the Service or its contents.
            </li>
            <li>
              Use the Service in any way that could interfere with, disrupt or
              negatively affect the Service or the servers or networks connected
              to the Service.
            </li>
            <li>
              Upload viruses or other malicious code or otherwise compromise the
              security of the Service.
            </li>
            <li>
              Forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any information transmitted to or through
              the Service.
            </li>
            <li>
              “Frame” or “mirror” any part of the Service without BeFriend’s
              prior written authorization.
            </li>
            <li>
              Use meta tags or code or other devices containing any reference to
              BeFriend or the Service (or any trademark, trade name, service
              mark, logo or slogan of BeFriend) to direct any person to any
              other website for any purpose.
            </li>
            <li>
              Modify, adapt, sublicense, translate, sell, reverse engineer,
              decipher, decompile or otherwise disassemble any portion of the
              Service, or cause others to do so.
            </li>
            <li>
              Use or develop any third-party applications that interact with the
              Service or other users’ Content or information without our written
              consent.
            </li>
            <li>
              use, access, or publish the BeFriend application programming
              interface without our written consent.
            </li>
            <li>
              Probe, scan or test the vulnerability of our Service or any system
              or network.
            </li>
            <li>
              Encourage or promote any activity that violates this Agreement.
            </li>
            <li>
              BeFriend may investigate and take any available legal action in
              response to illegal and/ or unauthorized uses of the Service,
              including termination of your account.
            </li>
            <li>
              Any software that we provide you may automatically download and
              install upgrades, updates, or other new features. You may be able
              to adjust these automatic downloads through your device’s
              settings.
            </li>
          </ul>

          <p>
            <strong>VII. Rights You Grant Us</strong>
            <br />
            Many of our Services let you create, upload, post, send, receive,
            and store content. When you do that, you retain whatever ownership
            rights in that content you had to begin with. But you grant us a
            license to use that content. While we’re not required to do so, we
            may access, review, screen, and delete your content at any time and
            for any reason, including to provide and develop the Service or if
            we think your content violates these Terms. You alone, though,
            remain responsible for the content you create, upload, post, send,
            or store through the Service. We always love to hear from our users.
            But if you provide feedback or suggestions, just know that we can
            use them without compensating you, and without any restriction or
            obligation to you.
          </p>
          <p>
            <strong>VIII. User Generated Content</strong>
            <br />
            Much of the content on our Service is produced by users and other
            third parties. Whether that content is posted publicly or sent
            privately, the content is the sole responsibility of the person or
            organization that submitted it. Although BeFriend reserves the right
            to review or remove all content that appears on the Service, we do
            not necessarily review all of it. So we cannot—and do not—take
            responsibility for any content that others provide through the
            Services. Through these Terms, we make clear that we do not want the
            Service put to bad uses. But because we do not review all content,
            we cannot guarantee that content on the Services will always conform
            to our Terms. If you have any concerns or want to report any
            concerning content, please promptly contact us at support@swipr.cc
            or use the reporting feature within the BeFriend mobile application.
          </p>
          <p>
            <strong>IX. Rules of Conduct</strong>
            <br />
            Safety of our users is of utmost importance for us. As a result, we
            have laid out the following Rules of Conduct which every user is
            subject to, whether they have an account or not. You are solely
            responsible for everything you write, post, upload, or make public.
            Furthermore, by using our Service, you also agree to our Community
            Guidelines. If you are using our Services, you are not allowed to
            send, upload, or post anything which:
          </p>

          <ul>
            <li>Could potentially cause any harm or damage to anyone</li>
            <li>
              Is mean, is bullying someone or is intended to harass, scare or
              upset anyone
            </li>
            <li>
              Is deliberately designed to provoke or antagonize people,
              especially trolling
            </li>
            <li>Uses rude or profane language</li>
            <li>Encourages dangerous or illegal activities</li>
            <li>Encourages any type of self-harm</li>
            <li>Contains any obscene or pornographic content</li>
            <li>
              Contains any threat of any kind, including threats to yourself or
              others
            </li>
            <li>
              Is racist or discriminates based on someone’s race, religion, age,
              gender, disability, or sexuality
            </li>
            <li>Infringes on individual’s privacy rights</li>
            <li>Competes with our Service or is spam</li>
            <li>
              Is illegal, could expose BeFriend to legal liability, or
              encourages people to get involved in anything which is illegal
              (for example, drugs, violence, or crime)
            </li>
            <li>
              Is defamatory or violates any third party’s rights, including
              breach of confidence, copyright, trademark, patent, trade secret,
              moral right, privacy right, right of publicity, or any other
              intellectual property right.
            </li>
            <li>
              Collects user content or information, or otherwise accesses the
              Services using automated means (such as harvesting bots, robots,
              spiders, or scrapers) without our prior permission
            </li>
            <li>
              Violates any robot exclusion headers of the site, if any, or
              bypasses or circumvents other measures employed to prevent or
              limit access to Services
            </li>
            <li>
              Shares, recompiles, decompiles, disassembles, reverse engineers,
              or makes or distributes any other form of, or any derivative work
              from, the Service
            </li>
            <li>
              Attempts to scrape or collect any personal or private information
              from other users or from the Service
            </li>
            <li>
              Pretends to come from someone other than you, or where you are
              impersonating someone else
            </li>
            <li>
              Intercepts or monitors, damages, or modifies any communication not
              intended for you
            </li>
            <li>Otherwise breaches the Terms of Service</li>
          </ul>

          <p>
            BeFriend does not tolerate ANY objectionable content or abusive
            users. Any objectionable content posted on BeFriend will be reviewed
            by our Content Safety team. We reserve the right to take down any
            objectionable content immediately without any notice.
          </p>
          <p>
            <strong>X. Privacy</strong>
            <br />
            Your privacy matters to us. You can learn how we handle your
            information when you use our Service by reading our Privacy Policy.
            We encourage you to give the Privacy Policy a careful look because,
            by using our Service, you agree that BeFriend can collect, use, and
            share your information consistent with that policy. Please note that
            BeFriend may ask for permission to collect and store on our servers
            your personal information such as Contacts on your phone. We cannot
            and do not collect information unless you give us explicit
            permission to do so. While we collect information (such as your
            Contacts) to help you take full advantage of our product features,
            we understand if you feel uncomfortable giving us permission. If so,
            you are encouraged to deny giving us permission. You also have the
            ability to delete any information you have given us from our
            servers; for example, if you have given us permission to your
            Contacts, but no longer wish to have them stored in our servers, let
            us know at support@swipr.cc. You can also delete your account or any
            other information from our servers by reaching us at that email. To
            understand more on how BeFriend collects, uses, shares, and stores
            your information, please read our Privacy Policy.
          </p>
          <p>
            <strong>XI. Respecting Other People’s Rights</strong>
            <br />
            BeFriend respects the rights of others. And so should you. You
            therefore may not use the Service, or enable anyone else to use the
            Services, in a manner that:
          </p>
          <ul>
            <li>
              Violates or infringes someone else’s rights of publicity, privacy,
              copyright, trademark, or other intellectual-property right.
            </li>
            <li>Bullies, harasses, or intimidates.</li>
            <li>spams or solicits our users.</li>
          </ul>

          <p>
            <strong>XII. Purchases</strong>
            <br />
            Generally. From time to time, BeFriend may offer products and
            services for purchase (“in app purchases”) through iTunes, Google
            Play, BeFriend direct billing or other payment platforms authorized
            by Guppir. If you choose to make an in app purchase, you will be
            prompted to confirm your purchase with the applicable payment
            provider, and your method of payment (be it your card or a third
            party account such as Google Play or iTunes) (your “Payment Method”)
            will be charged at the prices displayed to you for the service(s)
            you’ve selected as well as any sales or similar taxes that may be
            imposed on your payments, and you authorize BeFriend or the third
            party account, as applicable, to charge you.
          </p>
          <p>
            <strong>Auto_Renewal:</strong> If you purchase an auto-recurring
            periodic subscription, your Payment Method will continue to be
            billed for the subscription until you cancel. After your initial
            subscription commitment period, and again after any subsequent
            subscription period, your subscription will automatically continue
            for an additional equivalent period, at the price you agreed to when
            subscribing. If you do not wish your subscription to renew
            automatically, or if you want to change or terminate your
            subscription, you will need to log in to your third party account
            and follow instructions to cancel your subscription, even if you
            have otherwise deleted your account with us or if you have deleted
            the BeFriend application from your device. Deleting your account on
            BeFriend or deleting the BeFriend application from your device does
            not cancel your subscription; BeFriend will retain all funds charged
            to your Payment Method until you cancel your subscription on
            BeFriend or the third party account, as applicable. If you cancel
            your subscription, you may use your subscription until the end of
            your then-current subscription term, and your subscription will not
            be renewed after your then-current term expires.
          </p>
          <p>
            <strong>Virtual Items:</strong> From time to time, you may be able
            to purchase, a limited, personal, non-transferable,
            non-sublicensable, revocable license to use “virtual items,” such as
            gems (collectively, “Virtual Items”). Any Virtual Item balance shown
            in your account does not constitute a real-world balance or reflect
            any stored value, but instead constitutes a measurement of the
            extent of your license. Virtual Items do not incur fees for non-use,
            however, the license granted to you in Virtual Items will terminate
            in accordance with the terms of this Agreement, when BeFriend ceases
            providing the Service or your account is otherwise closed or
            terminated. BeFriend, in its sole discretion, reserves the right to
            charge fees for the right to access or use Virtual Items and/ or may
            distribute Virtual Items with or without charge. BeFriend may
            manage, regulate, control, modify or eliminate Virtual Items at any
            time. BeFriend shall have no liability to you or any third party in
            the event that Guppir exercises any such rights. Virtual Items may
            only be redeemed through the Service. ALL PURCHASES AND REDEMPTIONS
            OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL AND
            NON-REFUNDABLE. The provision of Virtual Items for use in the
            Service is a service that commences immediately upon the acceptance
            of your purchase of such Virtual Items. YOU ACKNOWLEDGE THAT
            BeFriend IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND
            THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED
            VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS
            VOLUNTARY OR INVOLUNTARY.
          </p>
          <p>
            <strong>Refunds:</strong> Generally, all charges for purchases are
            nonrefundable, and there are no refunds or credits for partially
            used periods. We may make an exception if the laws applicable in
            your jurisdiction provide for refunds For subscribers residing in
            Arizona, California, Connecticut, Illinois, Iowa, Minnesota, New
            York, North Carolina, Ohio and Wisconsin, the terms below apply: You
            may cancel your subscription, without penalty or obligation, at any
            time prior to midnight of the third business day following the date
            you subscribed. In the event that you die before the end of your
            subscription period, your estate shall be entitled to a refund of
            that portion of any payment you had made for your subscription which
            is allocable to the period after your death. In the event that you
            become disabled (such that you are unable to use the services of
            BeFriend) before the end of your subscription period, you shall be
            entitled to a refund of that portion of any payment you had made for
            your subscription which is allocable to the period after your
            disability by providing the company notice in the same manner as you
            request a refund as described below.
          </p>
          <p>
            <strong>To request a refund:</strong> If you subscribed using your
            Apple ID, refunds are handled by Apple, not BeFriend. To request a
            refund, go to iTunes, click on your Apple ID, select “Purchase
            history,” find the transaction and hit “Report Problem”. You can
            also submit a request at https://getsupport.apple.com. If you
            subscribed using your Google Play Store account or through BeFriend
            directly: please contact customer support at support@swipr.cc with
            your order number for the Google Play Store (you can find the order
            number by logging in to Google Wallet). Please also include the
            first name and telephone number associated with your account along
            with your order number.
          </p>

          <p>
            <strong>XIII. Copyright</strong>
            <br />
            BeFriend honors copyright laws, including the Digital Millennium
            Copyright Act. We therefore take reasonable steps to expeditiously
            remove from our Service any infringing material that we become aware
            of.
          </p>
          <p>
            <strong>XIV. Data Charges</strong>
            <br />
            By using the Service on your mobile phone (and/or any other device)
            you may be subject to charges by your Internet or mobile service
            provider, so check with them first if you are not sure, as you will
            be responsible for any such costs.
          </p>
          <p>
            <strong>XV. Modifying the Service and Termination</strong>
            <br />
            BeFriend is always striving to improve the Service and bring you
            additional functionality that you will find engaging and useful.
            This means we may add new product features or enhancements from time
            to time as well as remove some features, and if these actions do not
            materially affect your rights or obligations, we may not provide you
            with notice before taking them. We may even suspend the Service
            entirely, in which event we will notify you in advance unless
            extenuating circumstances, such as safety or security concerns,
            prevent us from doing so. You may terminate your account at any
            time, for any reason, by contacting us and letting us know you’d
            like to terminate your account. However if you use a third party
            payment account, you will need to manage in app purchases through
            such account (e.g., iTunes, Google Play) to avoid additional
            billing. We reserve the right to terminate your user account at any
            time, including if you have failed to comply with any of the
            provisions of these Terms, or if activities occur on your account
            which, in our sole discretion, would or might cause damage to or
            impair the Services or infringe or violate any third party rights,
            or violate any applicable laws or regulations. Upon such
            termination, you will not be entitled to any refund for purchases.
          </p>
          <p>
            <strong>XVI. Indemnity</strong>
            <br />
            You agree, to the extent permitted by law, to indemnify, defend, and
            hold harmless BeFriend, our affiliates, directors, officers,
            stockholders, employees, licensors, and agents from and against any
            and all complaints, charges, claims, damages, losses, costs,
            liabilities, and expenses (including attorneys’ fees) due to,
            arising out of, or relating in any way to: (a) your access to or use
            of the Service; (b) your content; and (c) your breach of these
            Terms.
          </p>
          <p>
            <strong>XVII. Disclaimers.</strong>
            <br />
            BEFRIEND PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS
            AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO WARRANTIES
            OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH
            RESPECT TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN),
            INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
            SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE OR NON-INFRINGEMENT. Guppir DOES NOT REPRESENT OR WARRANT
            THAT (A) THE SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE,
            (B) ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED, OR (C)
            THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICE
            WILL BE ACCURATE. BEFRIEND TAKES NO RESPONSIBILITY FOR ANY CONTENT
            THAT YOU OR ANOTHER USER OR THIRD PARTY POSTS, SENDS OR RECEIVES
            THROUGH THE SERVICE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED
            THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION
            AND RISK. BEFRIEND DISCLAIMS AND TAKES NO RESPONSIBILITY FOR ANY
            CONDUCT OF YOU OR ANY OTHER USER, ON OR OFF THE SERVICE.
          </p>
          <p>
            <strong>XVIII. Limitation of Liability</strong>
            <br />
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, LIGHTSPACE INC. AND OUR
            MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS,
            AGENTS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES,
            OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
            INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
            LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO
            ACCESS OR USE THE SERVICE; (B) THE CONDUCT OR CONTENT OF OTHER USERS
            OR THIRD PARTIES ON OR THROUGH THE SERVICE; OR (C) UNAUTHORIZED
            ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF LIGHTSPACE INC.
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT
            WILL LIGHTSPACE, INC.’S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING
            TO THE SERVICES EXCEED THE GREATER OF $100 USD OR THE AMOUNT YOU
            PAID LIGHTSPACE INC., IF ANY, IN THE LAST 12 MONTHS.
          </p>
          <p>
            <strong>XIX. Entire Agreement; Other.</strong>
            <br />
            This Agreement, along with the Privacy Policy, the Safety Tips,
            Community Guidelines, and any terms disclosed to you if you purchase
            or have purchased additional features, products or services we offer
            on the Service, contains the entire agreement between you and
            BeFriend regarding your relationship with BeFriend and the use of
            the Service. If any provision of this Agreement is held invalid, the
            remainder of this Agreement shall continue in full force and effect.
            The failure of BeFriend to exercise or enforce any right or
            provision of this Agreement shall not constitute a waiver of such
            right or provision. You agree that your BeFriend account is
            non-transferable and all of your rights to your account and its
            Content terminate upon your death. No agency, partnership, joint
            venture, fiduciary or other special relationship or employment is
            created as a result of this Agreement and you may not make any
            representations on behalf of or bind BeFriend in any manner.
          </p>
          <p>
            <strong>XX. Contact Us</strong>
            <br />
            BeFriend welcomes comments, questions, concerns, or suggestions.
            Please contact us at{' '}
            <a
              style={{
                textDecoration: 'underline',
              }}
              href="mailto:support@swipr.cc"
            >
              support@swipr.cc
            </a>
          </p>
          <br />
          <br />
          <br />
        </div>
        <DescFooter />
      </main>
    </>
  );
};

export default TermsPage;
