import React, { useState } from 'react';
import ScrollLock from 'react-scrolllock';
import MobileMenu from 'components/MobileMenu';

import logo from 'images/logo_dark.webp';
import instagram from 'images/instagram_white.webp';
import menu from 'images/menu_white.webp';

const BlackHeader = ({ text }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const onMobileMenuClick = () => {
    setShowMobileMenu((s) => !s);
  };
  return (
    <>
      <header className={'black'}>
        <div className={'background'} />
        <div className={'menu-container'}>
          <div className={'logo'}>
            <a href="/">
              <img src={logo} width={98} height={30} />
            </a>
          </div>
          <div className={'mobile-menu'}>
            <a href="#" onClick={onMobileMenuClick}>
              <img src={menu} width={32} height={32} />
            </a>
          </div>
          <div className={'item-container'}>
            <div className={'item'}>
              <a href="/community-guidelines">Community</a>
            </div>
            <div className={'item'}>
              <a href="/terms">Terms</a>
            </div>
            <div className={'item'}>
              <a href="/privacy-policy">Privacy</a>
            </div>
            <div className={'item'}>
              <a href="mailto:support@swipr.cc">Contact us</a>
            </div>
            <div className={'item icon'}>
              <a
                href="https://www.instagram.com/swipr_official/"
                target="_blank"
              >
                <img src={instagram} width={19.2} height={19.2} />
              </a>
            </div>
          </div>
        </div>
        <div className={'title-container'}>{text}</div>
      </header>
      <MobileMenu show={showMobileMenu} onMobileMenuClick={onMobileMenuClick} />
      <ScrollLock isActive={showMobileMenu} />
    </>
  );
};

export default BlackHeader;
