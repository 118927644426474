import * as React from 'react';
import PrivacyConsent from 'components/PrivacyConsent';

const DescFooter = () => {
  return (
    <>
      <PrivacyConsent />
      <footer className={'desc'}>
        <div>BeFriend Corporation. 2020-2024© All Rights Reserved</div>
      </footer>
    </>
  );
};

export default DescFooter;
