import * as React from 'react';
import Helmet from 'react-helmet';

const Meta = ({ titleSuffix }) => {
  return (
    <Helmet>
      <title>{`BeFriend | ${
        titleSuffix || 'Make new friends around the world'
      }`}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1"
      />
      <meta charset="UTF-8" />
      <meta property="og:image" content="/og_image.jpg" />
      <meta
        property="og:title"
        content={`BeFriend | ${
          titleSuffix || 'Make new friends around the world'
        }`}
      />
      <meta
        property="og:description"
        content="BeFriend - Best app to find new friends on Snapchat wanna make more friends on Snapchat? Simply SWIPE right and start to meet new friends from all over the world!"
      />
      <meta
        name="description"
        content="BeFriend - Best app to find new friends on Snapchat wanna make more friends on Snapchat? Simply SWIPE right and start to meet new friends from all over the world!"
      />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6288985965837099"
        crossorigin="anonymous"
      ></script>
    </Helmet>
  );
};

export default Meta;
